import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { BreakpointProvider } from './src/components/layout/Breakpoints'

import { siteMetadata } from './gatsby-config'

const queryClient = new QueryClient()

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <BreakpointProvider>{element}</BreakpointProvider>
  </QueryClientProvider>
)

const { sentryDSN } = siteMetadata
export const onClientEntry = () => {
  if (sentryDSN) {
    Sentry.init({
      dsn: sentryDSN,
      beforeSend(event, { originalException: error }) {
        if (error && error.message) {
          // this error happens in MapboxGL because the depth raster has empty tiles on land
          if (error.message.match(/dem dimension mismatch/i)) {
            return null
          }
          // this error happens when ResizeObserver not able to deliver all observations within a single animation frame
          if (error.message.match(/ResizeObserver loop limit exceeded/i)) {
            return null
          }
          // extension errors
          if (error.message.match(/extension context/i)) {
            return null
          }
        }
        return event
      },
      ignoreErrors: [/ReferenceError.* is not defined $/],
      whitelistUrls: ['mpatlas.org', 'staging.mpatlas.org'],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
    })
    window.Sentry = Sentry
  }
}
